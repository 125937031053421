require('./bootstrap');

require('alpinejs');

require('select2');

// require('tinymce');

// // Core - these two are required :-)
import tinymce from 'tinymce/tinymce';
import 'tinymce/themes/silver/theme';
//
// // Plugins
// import 'tinymce/plugins/paste/plugin';
import 'tinymce/plugins/link/plugin';
import 'tinymce/plugins/autoresize/plugin';
import 'tinymce/plugins/image/plugin';
import 'tinymce/plugins/autolink/plugin';
import 'tinymce/plugins/searchreplace/plugin';
// import 'tinymce/plugins/imagetools/plugin';
// import 'tinymce/plugins/media/plugin';
import 'tinymce/plugins/preview/plugin';
// import 'tinymce/plugins/pageembed/plugin';
// import 'tinymce/plugins/pdf/plugin';
import 'tinymce/plugins/lists/plugin';
import 'tinymce/plugins/code/plugin';
import './tinymce/plugins/media/plugin';
import './tinymce/plugins/manuals-link/plugin';
import './tinymce/plugins/app-link/plugin';
// import './tinymce/plugins/article-link/plugin';
import 'tinymce/models/dom';

jQuery(document).ready(function($){

    if($('.text-editor').length > 0){
        var editor_config = {
            selector: '.text-editor',
            body_class : 'custom-editor',
            skin: 'oxide-dark',
            menubar: false,
            statusbar: false,
            width: '100%',
            height: 200,
            resize: "vertical",
            relative_urls: false,
            convert_urls: false,
            remove_script_host : false,
            valid_elements: '+*[*]',
            forced_root_block: 'p',
            // newline_behavior: 'linebreak',
            contextmenu: 'copy paste | link image',
            toolbar: 'bold italic underline | numlist bullist | alignleft aligncenter alignright alignjustify | link manuals-link app-link |  image media | removeformat | code | preview |',
            plugins: 'image searchreplace autolink autoresize link media preview code manuals-link app-link lists',
            // Replaces every div with p when pasting
            // paste_preprocess : function(pl, o) {
            //     o.content = o.content
            //         .replace(/<div(.*?)>(.*?)<\/div>/gi,'<p$1>$2</p>')
            //     // .replace(/(.*?)<br\s?\/?>/gi,'<p>$1</p>')
            // },
            // images_upload_url: 'upload.php',
            file_picker_callback : function(callback, value, meta) {
                var x = window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName('body')[0].clientWidth;
                var y = window.innerHeight|| document.documentElement.clientHeight|| document.getElementsByTagName('body')[0].clientHeight;

                // var cmsURL = editor_config.path_absolute + 'laravel-filemanager?editor=' + meta.fieldname;
                var cmsURL = '/laravel-filemanager?editor=' + meta.fieldname;
                if (meta.filetype == 'image') {
                    cmsURL = cmsURL + "&type=Images";
                } else {
                    cmsURL = cmsURL + "&type=Files";
                }

                tinyMCE.activeEditor.windowManager.openUrl({
                    url : cmsURL,
                    title : 'File Manager',
                    width : x * 0.8,
                    height : y * 0.8,
                    resizable : "yes",
                    close_previous : "no",
                    onMessage: (api, message) => {
                        callback(message.content);
                    }
                });
            }
            // file_picker_callback: filemanager.tinyMceCallback,
            // file_picker_callback : function (field_name, url, type, win) {
            //     var x = window.innerWidth || document.documentElement.clientWidth || document.querySelector('body').clientWidth;
            //     var y = window.innerHeight || document.documentElement.clientHeight || document.querySelector('body').clientHeight;
            //
            //     var cmsURL = editor_config.path_absolute + 'laravel-filemanager?field_name=' + field_name;
            //     if (type == 'image') {
            //         cmsURL = cmsURL + "&type=Images";
            //     } else {
            //         cmsURL = cmsURL + "&type=Files";
            //     }
            //
            //     tinyMCE.activeEditor.windowManager.open({
            //         file: cmsURL,
            //         title: 'Filemanager',
            //         width: x * 0.8,
            //         height: y * 0.8,
            //         resizable: "yes",
            //         close_previous: "no"
            //     });
            // },
        };

        tinymce.init(editor_config);
    }

    if($('.select2-multiple').length > 0){
        let topCss;
        $('.select2-multiple')
            .select2({
                closeOnSelect: false
            })
            .on('select2:selecting select2:unselecting', function (evt) {
                var cur = evt.params.args.originalEvent.currentTarget;
                var value = evt.params.args.data.id;
                topCss = $(cur).closest('.select2-container')[0].style.top;

                if(value != 'all') {
                    $(this).find('option').first().removeAttr('selected');
                    // if($(this).select2('data')[0].id == 'all') {
                    //     $(this).val(value).change();
                    // }
                } else {
                    $(this).find('option').not(':first').removeAttr('selected');
                    $(this).val('all').change();
                }
            })
            .on('select2:select select2:unselect', function (evt) {
                let uldiv = $(this).siblings('span.select2').find('ul')
                let count = $(this).select2('data').length
                let values = $(this).val();
                var value = evt.params.data.id;

                if($('.select2-container:not(.select2)').length > 0){
                    $('.select2-container:not(.select2)')[0].style.top = topCss;
                }
                if(count!=0 && !$(this).find('option:selected').data('all')){
                    uldiv.html("<li>"+count+" items selected</li>");
                }
                if($(this).find('option:selected').data('all')){
                    $(this).find('option').not(':first').removeAttr('selected');
                    // $(this).find('option').not(':first').attr('disabled','disabled');
                    $(this).val('all').change();
                    $(this).addClass('selected-alldev');
                    // $(this).select2('destroy').select2({
                    //     closeOnSelect: false
                    // });
                }
                if(!$(this).find('option:selected').data('all')){
                    // $(this).find('option').removeAttr('disabled');
                    $(this).removeClass('selected-alldev');
                }
                if($(this).select2('data')[0].id == 'all') {
                    $(this).find('option').first().removeAttr('selected');
                    $(this).val(value).change();
                }
            })
            .on('select2:unselecting', function (evt) {
                let values = $(this).val();
                if($(this).find('option:selected').data('all')){
                    // $(this).select2('destroy').select2({
                    //     closeOnSelect: false
                    // });
                }
            })
            .on("select2:opening", function () {
                $('.underlay').show();
                // $('html, body').css({
                //     overflow: 'hidden',
                //     height: '100%'
                // });
            })
            .on("select2:close", function () {
                $('.underlay').hide();
                // $('html, body').css({
                //     overflow: 'auto',
                //     height: 'auto'
                // });
            });

        $(".select2-multiple").each(function (i, e) {
            let uldiv = $(e).siblings('span.select2').find('ul');
            let count = $(this).select2('data').length;

            if(count!=0 && !$(this).find('option:selected').data('all')){
                uldiv.html("<li>"+count+" items selected</li>");
            }
        });

        $(this).find('option').removeAttr('disabled');
    }
});
